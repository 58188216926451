#main-map-container {
  width: 70%;
  height: calc(100% - 74px);
  position: absolute;
}

#submap-container {
  width: 30%;
  height: calc(100% - 74px); /* Adjust as needed */
  position: absolute;
  right: 0%;
  background: #000;
}

.main-map {
  height: 100%;
  width: 100%;
  z-index: 0;
}

.main-map .maplibregl-map {
  height: 100%;
}

.main-map .maplibregl-control-container .maplibregl-ctrl-attrib {
  background-color: rgb(24, 26, 27);
  color: white;
  border: 5px;
}

.main-map .maplibregl-control-container  .maplibregl-ctrl-attrib a {
  color: white;
}

.main-map .maplibregl-control-container  .maplibregl-ctrl-attrib a:hover {
  color: gray;
}

.main-map .maplibregl-control-container  .maplibregl-ctrl-attrib .maplibregl-ctrl-attrib-button {
  background-color: white;
}

.submap {
  position: relative;
  height: 33.33%;
  right: 0px;
  border: 4px solid #000;
}
  
.submap-container .submap-element {
  flex: 1;
  border: 1px solid #000; /* Optional: for visual separation */
}

.submap .input-selector {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
}

.label {
  width: 100px;
  text-align: center;
}
