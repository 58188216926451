.layer-select-panel {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 10%;
    top: 0;
    width: 60%;
    color: #fff;
    /* background-color: black; */
    z-index: 999;
}

.filter-container {
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    gap: 10px;
    margin-left: 20px;
    width: 100%;
    height: 100%;
    margin-top: 0px;
}

.selector-container {
    display: flex;
    align-content: center;
    justify-content: start;
    margin: 10px;
}

.summary-button {
    display: flex;
    color:#fff;
    background-color: #000;
    border: 1px solid #fff;
    width: 120px;
    height: 42px;
    margin-bottom: 26px;
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.label {
    width: 80px;
    text-align: center;
}