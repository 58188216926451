.parent {
  display: flex;
  justify-content: flex-end;
}

.submap .maplibregl-map {
  height: 100%;
}

.submap .maplibregl-map .maplibregl-ctrl-attrib {
  background-color: rgb(24, 26, 27);
  color: white;
}

.submap .maplibregl-map .maplibregl-ctrl-attrib a {
  color: white;
}

.submap .maplibregl-map .maplibregl-ctrl-attrib a:hover {
  color: gray;
}

.submap .maplibregl-map .maplibregl-ctrl-attrib .maplibregl-ctrl-attrib-button {
  background-color: white;
}

.submap-container .submap-element {
  flex: 1;
}